import React, { Component } from 'react'

export default class BlogPostHero extends Component {
  render() {
    const image = require('../../assets/images/pexels-photo-273238.jpg')
    return (
      <div>
        <div
          className="bora-blog-title-wrap  bora-style-custom bora-feature-image"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="bora-header-transparent-substitute" />
          <div className="bora-blog-title-top-overlay" />
          <div className="bora-blog-title-overlay" />
          <div className="bora-blog-title-bottom-overlay" />
          <div className="bora-blog-title-container bora-container">
            <div
              className="bora-blog-title-content bora-item-pdlr"
              style={{ paddingTop: 400, paddingBottom: 80 }}
            >
              <header className="bora-single-article-head clearfix">
                <div className="bora-single-article-head-right">
                  <h1 className="bora-single-article-title">
                    Standard Post Type
                  </h1>
                  <div className="bora-blog-info-wrapper">
                    <div className="bora-blog-info bora-blog-info-font bora-blog-info-date">
                      <span className="bora-head">
                        <i className="icon_clock_alt" />
                      </span>
                      <a href="index.html">June 6, 2016</a>
                    </div>
                    <div className="bora-blog-info bora-blog-info-font bora-blog-info-author">
                      <span className="bora-head">
                        <i className="icon_documents_alt" />
                      </span>
                      <a
                        href="../../../#"
                        title="Posts by John Smith"
                        rel="author"
                      >
                        John Smith
                      </a>
                    </div>
                    <div className="bora-blog-info bora-blog-info-font bora-blog-info-category">
                      <span className="bora-head">
                        <i className="icon_folder-alt" />
                      </span>
                      <a href="../../../#" rel="tag">
                        Blog
                      </a>
                      <span className="gdlr-core-sep">,</span>{' '}
                      <a
                        href="../../../../category/post-format/index.html"
                        rel="tag"
                      >
                        Post Format
                      </a>
                    </div>
                    <div className="bora-blog-info bora-blog-info-font bora-blog-info-tag">
                      <span className="bora-head">
                        <i className="icon_tags_alt" />
                      </span>
                      <a href="../../../#" rel="tag">
                        News
                      </a>
                    </div>
                    <div className="bora-blog-info bora-blog-info-font bora-blog-info-comment-number">
                      <span className="bora-head">
                        <i className="icon_comment_alt" />
                      </span>2
                    </div>
                  </div>
                </div>
              </header>
            </div>
          </div>
        </div>
        <div className="bora-breadcrumbs">
          <div className="bora-breadcrumbs-container bora-container">
            <div className="bora-breadcrumbs-item bora-item-pdlr">
              {' '}
              <span property="itemListElement" typeof="ListItem">
                <a
                  property="item"
                  typeof="WebPage"
                  title="Go to bora."
                  href="https://demo.goodlayers.com/bora"
                  className="home"
                >
                  <span property="name">bora</span>
                </a>
                <meta property="position" content={1} />
              </span>•<span property="itemListElement" typeof="ListItem">
                <a
                  property="item"
                  typeof="WebPage"
                  title="Go to the Blog category archives."
                  href="../../../#"
                  className="taxonomy category"
                >
                  <span property="name">Blog</span>
                </a>
                <meta property="position" content={2} />
              </span>•<span property="itemListElement" typeof="ListItem">
                <span property="name">Standard Post Type</span>
                <meta property="position" content={3} />
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
