import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import get from 'lodash/get'

import Bio from '../components/Bio'
import Layout from '../components/layout/layout'
import Hero from '../components/Hero'
import { rhythm, scale } from '../utils/typography'
import BlogPostHero from '../components/Blog/BlogPostHero'

class BlogPostTemplate extends Component {
  renderInner() {
    return (
      <div className="bora-content-container bora-container">
        <div className=" bora-sidebar-wrap clearfix bora-line-height-0 bora-sidebar-style-none">
          <div className=" bora-sidebar-center bora-column-60 bora-line-height">
            <div className="bora-content-wrap bora-item-pdlr clearfix">
              <div className="bora-content-area">
                <article
                  id="post-1268"
                  className="post-1268 post type-post status-publish format-standard has-post-thumbnail hentry category-blog category-post-format tag-news"
                >
                  <div className="bora-single-article">
                    <div className="bora-single-article-content">
                      <p>
                        A wonderful serenity has taken possession of my entire
                        soul, like these sweet mornings of spring which I enjoy
                        with my whole heart. I am alone, and feel the charm of
                        existence in this spot,{' '}
                        <strong>
                          which was created for the bliss of souls like mine
                        </strong>. I am so happy, my dear friend, so absorbed in
                        the exquisite sense of mere tranquil existence, that I
                        neglect my talents. I should be incapable of drawing a
                        single stroke at the present moment; and yet I feel that
                        I never was a greater artist than now.
                      </p>
                      <h5>When, while the lovely valley teems with</h5>
                      <p>
                        vapour around me, and the meridian sun strikes the upper
                        surface of the impenetrable foliage of my trees, and but
                        a few stray gleams steal into the inner sanctuary, I
                        throw myself down among the tall grass by the trickling
                        stream; and, as I lie close to the earth, a thousand
                        unknown plants are noticed by me: when I hear the buzz
                        of the little world among the stalks, and grow familiar
                        with the countless indescribable forms of the insects
                        and flies, then I feel the presence of the Almighty, who
                        formed us in his own image, and the breath of that
                        universal love which bears and sustains us, as it floats
                        around us in an eternity of blist.
                      </p>
                      <blockquote>
                        <p>
                          I sink under the weight of the splendour of these
                          visions!A wonderful serenity has taken possession of
                          my entire soul, like these sweet mornings of spring
                          which
                        </p>
                      </blockquote>
                      <p>
                        I sink under the weight of the splendour of these
                        visions!A wonderful serenity has taken possession of my
                        entire soul, like these sweet mornings of spring which I
                        enjoy with my whole heart. I am alone, and feel the
                        charm of existence in this spot, which was created for
                        the bliss of souls like mine. I am so happy, my dear
                        friend, so absorbed in the exquis
                      </p>
                      <h6>I throw myself down among the tall grass</h6>
                      <p>
                        I should be incapable of drawing a single stroke at the
                        present moment; and yet I feel that I never was a
                        greater artist than now. When, while the lovely valley
                        teems with vapour around me, and the meridian sun
                        strikes the upper surface of the impenetrable foliage of
                        my trees, and but a few stray gleams steal into the
                        inner sanctuary, I throw myself down among the tall
                        grass by the trickling stream; and, as I lie close to
                        the earth, a thousand unknown plants are noticed by me:
                        when I hear the buzz of the little world among the
                        stalks, and grow familiar with the countless
                        indescribable forms of the insects and
                        <br />{' '}
                        <span
                          className="gdlr-core-space-shortcode"
                          style={{ marginTop: 30 }}
                        />
                      </p>
                      <p>
                        <img
                          className="alignnone size-full wp-image-4589"
                          src="upload/apple-desk-office-working-8841.jpg"
                          alt
                          width={1920}
                          height={1439}
                          srcSet="upload/apple-desk-office-working-8841-1.jpg 1920w, upload/apple-desk-office-working-8841-300x225.jpg 300w, upload/apple-desk-office-working-8841-768x576.jpg 768w, upload/apple-desk-office-working-8841-1024x767.jpg 1024w, upload/apple-desk-office-working-8841-60x45.jpg 60w"
                          sizes="(max-width: 1920px) 100vw, 1920px"
                        />
                      </p>{' '}
                      <span
                        className="gdlr-core-space-shortcode"
                        style={{ marginTop: 30 }}
                      />
                      <h6>Text, that where it came from it</h6>
                      <p>
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts. Separated they live in Bookmarksgrove right at
                        the coast of the Semantics, a large language ocean. A
                        small river named Duden flows by their place and
                        supplies it with the necessary regelialia. It is a
                        paradisematic country, in which roasted parts of
                        sentences fly into your mouth. Even the all-powerful
                        Pointing has no control about the blind texts it is an
                        almost unorthographic life One day however a small line
                        of blind text by the name of Lorem Ipsum decided to
                        leave for the far World of Grammar. The Big Oxmox
                        advised her not to do so, because there were thousands
                        of bad Commas, wild Question Marks and devious Semikoli,
                        but the Littl
                      </p>
                      <ul>
                        <li>Far far away, behind the word mountain</li>
                        <li>When she reached the first hills</li>
                        <li>A small river named Duden flows</li>
                        <li>A small river named Duden flows by their plat.</li>
                        <li>Far far away, behind the word mountain</li>
                      </ul>
                      <p>
                        Copy Writers ambushed her, made her drunk with Longe and
                        Parole and dragged her into their agency, where they
                        abused her for their projects again and again. And if
                        she hasn’t been rewritten, then they are still using
                        her.Far far away, behind the word mountains, far from
                        the countries Vokalia and Consonantia, there live the
                        blind texts. Separated they live in Bookmarksgrove right
                        at the coast of the Semantics, a large language ocean. A
                        small river named Duden flows by their plate.
                      </p>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  render() {
    return (
      <Layout>
        <div className="gdlr-core-page-builder-body">
          <BlogPostHero />
          {this.renderInner()}
        </div>
      </Layout>
    )
  }

  renderOption() {
    const post = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const { previous, next } = this.props.pageContext

    return (
      <Layout>
        <div className="gdlr-core-page-builder-body">
          <BlogPostHero />
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: rhythm(40),
              padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            }}
          >
            <Helmet title={`${post.frontmatter.title} | ${siteTitle}`} />
            <h1>{post.frontmatter.title}</h1>
            <p
              style={{
                ...scale(-1 / 5),
                display: 'block',
                marginBottom: rhythm(1),
                marginTop: rhythm(-1),
              }}
            >
              {post.frontmatter.date}
            </p>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            <hr
              style={{
                marginBottom: rhythm(1),
              }}
            />
            <Bio />

            <ul
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                listStyle: 'none',
                padding: 0,
              }}
            >
              {previous && (
                <li>
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                </li>
              )}

              {next && (
                <li>
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
